<template>
  <el-dialog
    :visible.sync="visible"
    :title="dataForm.id ? '修改' : '新增'"
    custom-class="dialog-warpper"
    width="1000px"
    top="5vh"
  >
    <el-form
      class="employee-form"
      :model="dataForm"
      :rules="dataFormRules"
      ref="employeeFormDom"
    >
      <el-card>
        <div slot="header">基本信息</div>
        <div class="pic-descriptions__wrapper">
          <el-descriptions :column="2" border>
            <el-descriptions-item
              label="姓名"
              :contentStyle="{ width: '300px' }"
            >
              <template slot="label">
                <!-- <i class="el-icon-user"></i> -->
                <span class="requireSyb">*</span>
                姓名
              </template>
              <el-form-item prop="name">
                <el-input
                  v-model="dataForm.name"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item label="性别">
              <template slot="label">
                <span class="requireSyb">*</span>性别
              </template>

              <el-form-item prop="sex">
                <el-select v-model="dataForm.sex" clearable>
                  <el-option
                    v-for="item in ['男', '女']"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item label="联系电话">
              <template slot="label">
                <span class="requireSyb">*</span>联系电话
              </template>
              <el-form-item prop="tel">
                <el-input
                  v-model="dataForm.tel"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item label="所属部门">
              <template slot="label">
                <span class="requireSyb">*</span>所属部门
              </template>
              <el-form-item prop="did">
                <el-select v-model="dataForm.did" clearable>
                  <el-option
                    v-for="item in departmentList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item label="所属班组">
              <el-select v-model="dataForm.tid" clearable>
                <el-option
                  v-for="item in teamList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-descriptions-item>

            <el-descriptions-item label="角色">
              <template slot="label">
                <span class="requireSyb">*</span>角色
              </template>
              <el-form-item prop="rid">
                <el-select
                  v-model="dataForm.rid"
                  clearable
                  filterable
                  @change="selectRole"
                >
                  <el-option
                    v-for="item in roleList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-descriptions-item>

            <el-descriptions-item label="账号">
              <template slot="label">
                <span class="requireSyb">*</span>账号
              </template>
              <el-form-item prop="username">
                <el-input
                  v-model="dataForm.username"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item label="密码">
              <template slot="label">
                <span v-if="!dataForm.id" class="requireSyb">*</span>密码
              </template>

              <el-form-item prop="password">
                <el-input
                  v-model="dataForm.password"
                  :placeholder="
                    dataForm.id ? '需要修改密码时才需要输入' : '请输入'
                  "
                  clearable
                ></el-input>
              </el-form-item>
            </el-descriptions-item>
          </el-descriptions>

          <div class="pic-box">
            <div class="headerPic">
              <!-- 员工人像 -->
              <el-upload
                disabled
                class="avatar-uploader"
                :action="$http.employee.uploadHeadPic"
                :on-success="uploadSuccess"
                :headers="{ token: $store.state.token }"
                :multiple="false"
                :show-file-list="false"
              >
                <div class="img-box" v-if="dataForm.faceComparisonPic">
                  <el-image
                    fit="contain"
                    :src="$http.common.facePicUrl + dataForm.faceComparisonPic"
                    class="avatar"
                  />
                  <div class="delete-box" @click.stop="faceDeleteHeadHandler">
                    <i class="delete-icon el-icon-delete"></i>
                  </div>
                </div>

                <div
                  v-else
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  "
                >
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                  <span style="margin-top: 10px">人脸</span>
                </div>
              </el-upload>
            </div>
            <div class="facePic">
              <!-- 员工头像 -->
              <el-upload
                class="avatar-uploader"
                :action="$http.employee.uploadHeadPic"
                :on-success="uploadSuccess"
                :headers="{ token: $store.state.token }"
                :multiple="false"
                :show-file-list="false"
              >
                <div class="img-box" v-if="dataForm.headerPic">
                  <el-image
                    fit="contain"
                    :src="$http.common.aliHeaderPic + dataForm.headerPic"
                    class="avatar"
                  />
                  <div class="delete-box" @click.stop="deleteHeadHandler">
                    <i class="delete-icon el-icon-delete"></i>
                  </div>
                </div>

                <div
                  v-else
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  "
                >
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                  <span style="margin-top: 10px">头像</span>
                </div>
              </el-upload>
            </div>
          </div>
        </div>

        <el-descriptions :column="2" border>
          <el-descriptions-item
            label="所属公司"
            :contentStyle="{ width: '300px' }"
          >
            <el-form-item prop="ssgs">
              <el-input
                v-model="dataForm.ssgs"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item label="工作地点">
            <el-form-item prop="gzdd">
              <el-input
                v-model="dataForm.gzdd"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="职务">
            <el-form-item prop="zhiwu">
              <el-input
                v-model="dataForm.zhiwu"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item label="岗位">
            <el-input
              v-model="dataForm.gangwei"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-descriptions-item>

          <el-descriptions-item label="入职时间">
            <el-date-picker
              v-model="dataForm.ruzhiDate"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="date"
              placeholder="选择日期"
              clearable
              @change="ruzhiDateChange"
            ></el-date-picker>
          </el-descriptions-item>
          <el-descriptions-item label="入职年限">
            <el-form-item prop="rznx">
              <el-input
                v-model="dataForm.rznx"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="身份证号">
            <el-form-item prop="shenfenzheng">
              <el-input
                v-model="dataForm.shenfenzheng"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item
            label="学历"
            :labelStyle="{ borderTop: 'none' }"
          >
            <el-input
              v-model="dataForm.xueli"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-descriptions-item>

          <el-descriptions-item label="民族">
            <el-input
              v-model="dataForm.minzu"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="政治面貌">
            <el-input
              v-model="dataForm.zzmm"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-descriptions-item>

          <el-descriptions-item label="籍贯">
            <el-form-item prop="jiguan">
              <el-input
                v-model="dataForm.jiguan"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item label="户口地址">
            <el-form-item prop="hkdz">
              <el-input
                v-model="dataForm.hkdz"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="户口类型">
            <el-form-item prop="hklx">
              <el-input
                v-model="dataForm.hklx"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item label="现住址">
            <el-input
              v-model="dataForm.address"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-descriptions-item>

          <el-descriptions-item label="紧急联系人">
            <el-input
              v-model="dataForm.jjlxr"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="紧急联系人电话">
            <el-input
              v-model="dataForm.jjlxfs"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-descriptions-item>

          <el-descriptions-item label="与紧急联系人的关系">
            <el-form-item prop="jjlxrgx">
              <el-input
                v-model="dataForm.jjlxrgx"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item label="资质名称">
            <el-input
              v-model="dataForm.zizhiName"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-descriptions-item>

          <el-descriptions-item label="资质证号">
            <el-input
              v-model="dataForm.zizhiNumber"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-descriptions-item>
          <el-descriptions-item label="资质有效期">
            <el-date-picker
              v-model="dataForm.zizhiValidityDate"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="date"
              placeholder="选择日期"
              clearable
            ></el-date-picker>
          </el-descriptions-item>

          <el-descriptions-item label="合同有效期">
            <el-date-picker
              v-model="dataForm.hetongValidityDate"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="date"
              placeholder="选择日期"
              clearable
            ></el-date-picker>
          </el-descriptions-item>
          <el-descriptions-item label="员工状态">
            <el-form-item prop="status">
              <el-select v-model="dataForm.status" disabled>
                <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>
        </el-descriptions>
      </el-card>

      <el-card style="margin-top: 5px">
        <div slot="header">员工其它资料(身份证、驾驶证、资格证等)</div>

        <el-upload
          class="avatar-uploader"
          :action="$http.common.upload"
          list-type="picture-card"
          :on-success="picDataUploadSuccess"
          :headers="{ token: $store.state.token }"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :file-list="picData_arr"
        >
          <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible" append-to-body>
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </el-card>
    </el-form>

    <div>
      <p>修改记录</p>
      <el-input
        type="textarea"
        v-model="dataForm.log"
        disabled
        rows="6"
      ></el-input>
    </div>

    <template slot="footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="submitHandler">确认</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: "",
      visible: false,
      dataForm: {
        id: "",
        rid: "",
        did: "",
        tid: "",
        headerPic: "",
        name: "",
        number: "",
        sex: "",
        shenfenzheng: "",
        minzu: "",
        zzmm: "",
        xueli: "",
        tel: "",
        address: "",
        jjlxr: "",
        jjlxfs: "",
        gangwei: "",
        ruzhiDate: "",
        hetongValidityDate: "",
        zizhiName: "",
        zizhiNumber: "",
        zizhiValidityDate: "",
        picData: "",
        username: "",
        password: "",
        jiguan: "",
        hklx: "",
        hkdz: "",
        ssgs: "",
        jjlxrgx: "",
        gzdd: "",
        zhiwu: "",
        rznx: "",
        lizhiDate: "",
        faceComparisonPic: "",
      },
      picData_arr: [], // 图片资料显示列表
      statusList: [
        {
          value: 0,
          label: "入职申请审批中",
        },
        {
          value: 1,
          label: "在职",
        },
        {
          value: 2,
          label: "已拒绝入职",
        },
        {
          value: 3,
          label: "修改员工信息审批中",
        },
        {
          value: 4,
          label: "离职审批中",
        },
        {
          value: 5,
          label: "已离职",
        },
      ],
      departmentList: [], // 部门列表
      roleList: [], // 角色列表
      teamList: [], // 班组列表

      dataFormRules: {
        name: [{ required: true, message: "姓名不能为空", trigger: "blur" }],
        sex: [{ required: true, message: "性别不能为空", trigger: "blur" }],
        rid: [{ required: true, message: "角色不能为空", trigger: "blur" }],
        tel: [{ required: true, message: "联系电话不能为空", trigger: "blur" }],
        username: [
          { required: true, message: "账号不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    init(id) {
      this.visible = true;
      this.dataInit();
      if (id) {
        this.dataForm.id = id;
        this.getEmployeeInfo();
      }
      this.visible = true;
      this.getRoleList();
      this.getDepartmentList();
      this.getTeamList();
    },
    selectRole(value) {
      console.log(value);
    },
    // 数据初始化
    dataInit() {
      Object.keys(this.dataForm).forEach((key) => {
        this.dataForm[key] = "";
      });
      this.picData_arr = [];
      this.$nextTick(() => {
        this.$refs["employeeFormDom"].clearValidate();
      });
    },
    // 获取员工信息
    getEmployeeInfo() {
      this.$http.employee.info({ id: this.dataForm.id }).then((res) => {
        if (res.data) {
          res.data.tid = res.data.tid == 0 ? "" : res.data.tid;
          res.data.did = res.data.did == 0 ? "" : res.data.did;
        }
        this.dataForm = res.data || this.dataForm;

        if (res.data && res.data.picData) {
          this.picData_arr = res.data.picData.split("_").map((ele) => {
            return (ele = { url: this.$http.common.pic + ele });
          });
        }
      });
    },
    // 获取角色列表
    getRoleList() {
      this.$http.role.list().then((res) => {
        if (!res.data) return;
        this.roleList = res.data || [];
      });
    },

    // 获取部门列表
    getDepartmentList() {
      this.$http.department.list({ page: 1, limit: 99999 }).then((res) => {
        if (!res.data) return;
        this.departmentList = res.data.list || [];
      });
    },
    // 获取班组列表
    getTeamList() {
      this.$http.team.list({ page: 1, limit: 99999 }).then((res) => {
        if (!res.data) return;
        this.teamList = res.data.list || [];
      });
    },

    // 提交处理
    submitHandler() {
      if (this.roleList.length > 0) {
        this.roleList.forEach((item) => {
          if (item.id == this.dataForm.rid) {
            if (item.name.indexOf("班组负责人") != -1 && !this.dataForm.tid) {
              this.$message.error("当前角色必选选择班组");
              some;
            }
          }
        });
      }
      // 整理提交的图片
      this.dataForm.picData = "";
      if (this.picData_arr.length > 0) {
        let arr = [];
        this.picData_arr.forEach((item) => {
          arr.push(
            item.url.substring(item.url.lastIndexOf("/") + 1, item.url.length)
          );
        });
        this.dataForm.picData = arr.join("_");
      }

      // 判断是修改页面还是新增页面
      if (this.dataForm.id) {
        let res = [];
        this.$refs["employeeFormDom"].validateField(
          ["name", "sex", "tel", "username", "rid"],
          (valid) => {
            res.push(valid);
          }
        );
        res = res.some((ele) => {
          return ele !== "";
        });

        if (!res) {
          this.updateEmployeeHandler();
        } else {
          this.$message.error("请完善信息");
        }
      } else {
        this.$refs["employeeFormDom"].validate((valid) => {
          if (valid) {
            this.addEmployeeHandler();
          } else {
            this.$message.error("请完善信息");
          }
        });
      }
    },
    // 修改员工信息
    updateEmployeeHandler() {
      this.$http.employee.update(this.dataForm).then((res) => {
        this.$message({
          message: "修改成功",
          type: "success",
          duration: 500,
          onClose: () => {
            this.visible = false;
            this.$nextTick(() => {
              this.$emit("refreshDataList");
            });
          },
        });
      });
    },
    // 新增员工
    addEmployeeHandler() {
      this.$http.employee.save(this.dataForm).then((res) => {
        this.$message({
          message: "新增成功",
          type: "success",
          duration: 500,
          onClose: () => {
            this.visible = false;
            this.$nextTick(() => {
              this.$emit("refreshDataList");
            });
          },
        });
      });
    },

    // 上传头像
    uploadSuccess(res, file) {
      this.dataForm.headerPic = file.response.data;
    },
    // 上传图片资料
    picDataUploadSuccess(response) {
      if (response.code === 0) {
        this.picData_arr.push({
          url: this.$http.common.pic + response.data,
        });
      }
    },
    deleteHeadHandler() {
      this.dataForm.headerPic = "";
    },
    handleRemove(file, fileList) {
      this.picData_arr = fileList;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    faceDeleteHeadHandler() {
      this.dataForm.faceComparisonPic = "";
    },
    // 入职时间发生变化
    ruzhiDateChange(e) {
      if (!e) {
        this.dataForm.rznx = "";
        return;
      }

      let select_date = new Date(e);
      let now_date = new Date();
      this.dataForm.rznx = now_date.getFullYear() - select_date.getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() {
  .el-descriptions {
    .el-descriptions-item__label {
      width: 100px;
      text-align: center;
      color: #606266;
    }

    .el-descriptions-item__content {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .el-select {
    width: 100%;
  }

  .el-date-editor {
    width: 100%;
  }

  .el-form-item {
    margin-bottom: 0;
  }
}

:deep() {
  .pic-descriptions__wrapper {
    position: relative;
    padding-right: 170px;

    .pic-box {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 170px;
      border: 1px solid #ebeef5;
      border-left: 0;
      padding: 5px;
      display: flex;
      flex-direction: column;

      .headerPic {
        height: 50%;
      }
      .facePic {
        height: 50%;
      }

      .avatar-uploader {
        height: 100%;

        .el-upload {
          width: 100%;
          height: 100%;
          border: 1px dashed #d9d9d9;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          cursor: pointer;
          overflow: hidden;
          transition: 0.1s;

          .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            text-align: center;
          }
        }

        .el-upload:hover {
          border-color: #11bb8d;
        }

        .img-box {
          position: relative;
          width: 100%;
          height: 100%;

          .avatar {
            width: 100%;
            height: 100%;
            display: block;
          }

          .delete-box {
            width: fit-content;
            height: fit-content;
            padding: 10px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            background-color: rgba($color: #000000, $alpha: 0.3);
            display: none;

            .delete-icon {
              color: #fff;
              font-size: 20px;
            }
          }
        }

        .img-box:hover {
          .delete-box {
            display: block;
          }
        }
      }
    }
  }
}

// 必填标识
.requireSyb {
  color: red;
  margin-right: 2px;
  font-size: 18px;
}
</style>